<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text ps-0 pt-5 mt-5">
          <h1>非凸动态</h1>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="container-lg px-4 py-5">
        <div v-for="item of newsToDisplay" :key="item.path" class="row px-2 py-4 border-bottom">
          <div class="col-sm-12 col-lg-3 p-1">
            <router-link :to="item.path">
              <img :src="item.image" :alt="item.title" class="img-fluid rounded" />
            </router-link>
          </div>
          <div class="col-sm-12 col-lg-9 d-flex flex-column">
            <router-link class="text-decoration-none title fs-3 fw-bold text-black" :to="item.path"
            v-if="!item.path.startsWith('http')"
            >
            {{ item.title}}
            </router-link>
          <a class="text-decoration-none title fs-3 fw-bold text-black" :href="item.path"
            v-else>
            {{ item.title}}
          </a>
            <div class="info">
              <div class="tags">
                <a v-for="tag of item.tags" :key="tag" href="#!"
                  class="badge me-1 bg-primary text-decoration-none link-light">{{ tag }}</a>
              </div>
              <div class="text-muted fst-italic pt-2">
                {{ item.date }}
              </div>
            </div>
            <div class="content mt-3">
              {{ item.abstract }}
            </div>
          </div>
        </div>

        <nav style="margin-top: 1.5rem">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-for="index of Array(pages).fill(0).map((v,k) => k)" :key="index" :class="{'active': index == 0}">
              <a class="page-link" @click="pageItemClicked(index, $event)" href="#">{{ index+1 }}</a>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      news: [
        {
          title: "非凸联合创始人李佐凡：将量化作为自己的终身事业",
          image: require("../assets/img/news/10/01-李佐凡.jpg"),
          path: "/news10",
          tags: ["人物", "联合创始人"],
          date: "2022年6月24日",
          abstract: "李佐凡说，“我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的算法交易实现跨越式发展。”"
        },
        {
          title: "非凸科技钻石赞助第四届Rust China Conf 2024",
          image: require("../assets/img/news/88封面.jpg"),
          path: "https://mp.weixin.qq.com/s/66cBmLnfGSKgcGRWmS3AIA",
          tags: ["新闻", "Rust开发者大会"],
          date: "2024年9月12日",
          abstract: "全力协助大会顺利开展，共同为中国Rustaceans带来一场精彩的技术交流盛宴。"
        },
        {
          title: "携手浙商证券、华锐技术，共话交易技术的创新与应用",
          image: require("../assets/img/news/87封面.jpg"),
          path: "https://mp.weixin.qq.com/s/UOtr6BJJJgFnTkPKpK886A",
          tags: ["新闻", "浙商证券"],
          date: "2024年9月9日",
          abstract: "与管理人、资方共同分享前沿观点，探讨交易技术的创新与应用以及未来合作新模式。"
        },
        {
          title: "徐州市委书记宋乐伟一行莅临非凸科技徐州分公司调研",
          image: require("../assets/img/news/86封面.jpg"),
          path: "https://mp.weixin.qq.com/s/SjgbNdLTtizYKfsxtmC8UA",
          tags: ["新闻", "指导交流"],
          date: "2024年8月5日",
          abstract: "详细了解非凸数智交易产品的生态体系以及AI算力赋能的实践成果，并进行了现场指导与交流。"
        },
        {
          title: "携手中粮期货、国信证券共同见证数智交易前沿力量",
          image: require("../assets/img/news/85/封面.jpg"),
          path: "/news85",
          tags: ["新闻", "中粮期货&国信证券"],
          date: "2024年7月23日",
          abstract: "与行业专家、私募管理人共同探讨如何推进产业与金融的深度融合，以及实现科技创新与生态合作的新模式。"
        },
        {
          title: "携手浙商证券，共探科技创新与生态合作新趋势",
          image: require("../assets/img/news/84封面.jpg"),
          path: "https://mp.weixin.qq.com/s/lNInMGwFPr3V6eYgrxnQvg",
          tags: ["新闻", "浙商证券"],
          date: "2024年7月12日",
          abstract: "与多方共同探讨如何实现科技创新与金融服务的深度融合，以及可持续发展与生态伙伴的合作共赢。"
        },
        {
          title: "携手广发证券，共探科技赋能下的私募发展生态圈",
          image: require("../assets/img/news/83封面.jpg"),
          path: "https://mp.weixin.qq.com/s/t4A46tuWHpI0ynxKuGqW0A",
          tags: ["新闻", "广发证券"],
          date: "2024年7月3日",
          abstract: "与私募管理人、资方共同探讨新形势下私募基金的高质量发展及科技赋能下券商综合服务的转型优化。"
        },
        {
          title: "非凸科技受邀参加，探讨数智交易如何助力投资者应对市场挑战",
          image: require("../assets/img/news/82/封面.jpg"),
          path: "/news82",
          tags: ["新闻", "国信证券"],
          date: "2024年6月7日",
          abstract: "探讨如何在复杂多变的投资环境下应对机遇与挑战，以及如何更好地为投资者提供全链路数智化交易解决方案。"
        },
        {
          title: "携手国泰君安证券，探讨交易大时代下的创新与共赢",
          image: require("../assets/img/news/81/封面.jpg"),
          path: "/news81",
          tags: ["新闻", "国泰君安证券"],
          date: "2024年5月27日",
          abstract: "探讨如何实现科技创新与金融服务的深度融合，以及可持续发展与生态伙伴的合作共赢。"
        },
        {
          title: "非凸科技受邀出席第二届HELLO FUTURE.新生代创新大会",
          image: require("../assets/img/news/80/封面.jpg"),
          path: "/news80",
          tags: ["新闻", "创新大会"],
          date: "2024年5月6日",
          abstract: "探讨了科技创新赋能投资的新趋势，展现了新生代力量在行业变革中的重要作用。"
        },
        {
          title: "华泰证券上海普陀区江宁路营业部携手非凸科技，共话科技赋能投资新趋势",
          image: require("../assets/img/news/79/封面.jpg"),
          path: "/news79",
          tags: ["新闻", "华泰证券"],
          date: "2024年4月28日",
          abstract: "探讨如何通过科技创新提升交易效率与用户体验，为投资者提供更高效、更智能的金融服务。"
        },
        {
          title: "太平洋证券携手非凸科技，共同探讨交易创新与生态合作",
          image: require("../assets/img/news/78/封面.jpg"),
          path: "/news78",
          tags: ["新闻", "太平洋证券"],
          date: "2024年4月7日",
          abstract: "与私募管理人、资管机构负责人共同探讨了交易创新与生态合作新路径。"
        },
        {
          title: "非凸科技跻身GDC全球创新项目TOP16，掀起AI创新热潮",
          image: require("../assets/img/news/77/封面.jpg"),
          path: "/news77",
          tags: ["新闻", "创新大赛"],
          date: "2024年3月29日",
          abstract: "凭借出色的产品性能、领先的技术创新以及突出的合作案例，赢得专家评审团的高度认可，成功晋级决赛。"
        },
        {
          title: "走进上交高金，探讨机器学习在金融领域的应用",
          image: require("../assets/img/news/76/封面.jpg"),
          path: "/news76",
          tags: ["新闻", "校企合作"],
          date: "2024年3月25日",
          abstract: "分享了机器学习在金融领域的应用，以及大模型在股票市场的迁移与影响。"
        },
        {
          title: "非凸科技携手华鑫证券，共探大模型时代投资赋能",
          image: require("../assets/img/news/75/封面.jpg"),
          path: "/news75",
          tags: ["新闻", "华鑫证券"],
          date: "2024年3月20日",
          abstract: "与管理人、资方共同探讨了大模型技术在投资领域的应用优势与发展前景。"
        },
        {
          title: "非凸科技荣获复旦大学管理学院“年度合作伙伴”奖",
          image: require("../assets/img/news/74/封面.jpg"),
          path: "/news74",
          tags: ["新闻", "校企合作"],
          date: "2024年1月30日",
          abstract: "希望通过双方的互动交流和紧密合作，碰撞出更多创新灵感，实现校企共赢发展。"
        },
        {
          title: "海通证券携手非凸科技，共谋行业未来和生态合作",
          image: require("../assets/img/news/73/封面.jpg"),
          path: "/news73",
          tags: ["新闻", "海通证券"],
          date: "2024年1月29日",
          abstract: "共同探讨量化行业发展，未来策略展望及合作方向等热点话题，为科技赋能下的量化领域提供了深入见解。"
        },
        {
          title: "非凸算法上线华鑫证券，携手共创智能交易新生态",
          image: require("../assets/img/news/72/封面.jpg"),
          path: "/news72",
          tags: ["新闻", "华鑫证券"],
          date: "2024年1月23日",
          abstract: "合作过程中，非凸科技直面需求、及时响应的态度，稳扎稳打的技术实力和工程能力，皆获得了极大认可。"
        },
        {
          title: "回馈科教，非凸科技助力第48届ICPC亚洲区决赛",
          image: require("../assets/img/news/71/封面.jpg"),
          path: "/news71",
          tags: ["新闻", "ICPC"],
          date: "2024年1月17日",
          abstract: "作为此次赛事的支持方之一，希望携手各方共同推动计算机科学和技术的发展。"
        },
        {
          title: "非凸科技受邀出席北京基金小镇&华鑫证券联合分享会",
          image: require("../assets/img/news/70/封面.jpg"),
          path: "/news70",
          tags: ["新闻", "华鑫证券"],
          date: "2024年1月12日",
          abstract: "与管理人、资方共同探讨了私募行业备案现状、合规要点、交易赋能以及未来合作机会。"
        },
        {
          title: "非凸科技受邀参加财联社圆桌论坛“AI如何赋能量化交易”",
          image: require("../assets/img/news/69/封面.jpg"),
          path: "/news69",
          tags: ["新闻", "财联社投资年会"],
          date: "2023年12月22日",
          abstract: "与行业专业人士就“AI技术下的行业变革、AI技术之挑战”等话题进行了深入探讨。"
        },
        {
          title: "携手海通证券，畅谈量化行业发展趋势及未来合作机会",
          image: require("../assets/img/news/68/封面.jpg"),
          path: "/news68",
          tags: ["新闻", "海通证券"],
          date: "2023年12月21日",
          abstract: "通过分享实践案例和创新思路，与会者们相互启发，为推动行业发展贡献了积极力量。"
        },
        {
          title: "非凸科技受邀出席“第十五届HED峰会”，探讨量化之争",
          image: require("../assets/img/news/67/封面.jpg"),
          path: "/news67",
          tags: ["新闻", "HED峰会"],
          date: "2023年12月20日",
          abstract: "与行业专家就今年量化投资策略市场表现、市场争议、风险控制以及未来发展等问题展开了深度交流。"
        },
        {
          title: "“乘长会客厅”深圳站，德邦证券携手非凸科技共探量化机遇与生态合作",
          image: require("../assets/img/news/66/封面.jpg"),
          path: "/news66",
          tags: ["新闻", "德邦证券"],
          date: "2023年12月18日",
          abstract: "未来是一个生态合作和融合创新的时代，非凸科技将聚焦技术，做好支撑，助力行业伙伴持续发展。"
        },
        {
          title: "非凸科技助力第48届ICPC亚洲区域赛（杭州）成功举办",
          image: require("../assets/img/news/65/封面.jpg"),
          path: "/news65",
          tags: ["新闻", "ICPC"],
          date: "2023年12月13日",
          abstract: "作为此次赛事的主要支持之一，希望与各方共同努力，为国家计算机学科人才培养做出积极贡献。"
        },
        {
          title: "非凸科技荣获第七届全球青年创新大会“金领奖”",
          image: require("../assets/img/news/64/封面.jpg"),
          path: "/news64",
          tags: ["新闻", "荣获奖项"],
          date: "2023年12月11日",
          abstract: "未来，非凸科技也将秉承行业初心，以最具实力的技术及产品研发能力，持续推动数智化交易未来。"
        },
        {
          title: "世纪证券携手非凸科技，多元探讨量化发展及生态合作",
          image: require("../assets/img/news/63/封面.jpg"),
          path: "/news63",
          tags: ["新闻", "世纪证券"],
          date: "2023年12月7日",
          abstract: "与艾悉资产、竹润投资、三亚澜和等资方、管理人，就当下量化发展及生态合作展开了多元探讨。"
        },
        {
          title: "德邦证券联合非凸科技，开展“乘长会客厅”北京站活动",
          image: require("../assets/img/news/62/封面.jpg"),
          path: "/news62",
          tags: ["新闻", "德邦证券"],
          date: "2023年11月30日",
          abstract: "与机构投资者、私募管理人等行业资深人士，就算法赋能交易、策略研发效率等方面展开了深度交流。"
        },
        {
          title: "海通证券携手非凸科技，共同探讨私募生态圈的共建共赢",
          image: require("../assets/img/news/61/封面.jpg"),
          path: "/news61",
          tags: ["新闻", "海通证券"],
          date: "2023年11月21日",
          abstract: "与嘉宾就算法交易的未来发展以及私募生态圈的共建共赢展开了深度探讨，并对未来行业发展与合作进行了展望。"
        },
        {
          title: "RustCC分享会｜非凸科技与开发者共同探讨Rust安全进化",
          image: require("../assets/img/news/60/封面.jpg"),
          path: "/news60",
          tags: ["新闻", "技术交流"],
          date: "2023年10月23日",
          abstract: "从编程语言的安全性、C/C++的安全性问题、Rust的解决方案以及Rust FFI四大角度进行了详细分享。"
        },
        {
          title: "非凸科技受邀出席源创会，探讨数据技术的未来发展",
          image: require("../assets/img/news/59/封面.jpg"),
          path: "/news59",
          tags: ["新闻", "源创会"],
          date: "2023年10月10日",
          abstract: "与业界专家们共同探讨了数据存储、数据分析、数据挖掘等前沿技术。"
        },
        {
          title: "国投证券（原”安信证券“）携手共议量化行业的赋能发展",
          image: require("../assets/img/news/58/封面.jpg"),
          path: "/news58",
          tags: ["新闻", "国投证券"],
          date: "2023年10月9日",
          abstract: "与资方管理人就如何赋能量化私募可持续发展，给出了精彩纷呈的见解与讨论。"
        },
        {
          title: "国联证券联合非凸科技举办量化闭门会议",
          image: require("../assets/img/news/57/封面.jpg"),
          path: "/news57",
          tags: ["新闻", "国联证券"],
          date: "2023年9月22日",
          abstract: "与资方管理人就机构业务、算法交易、量化策略等方面展开了深度交流，分享了各自见解与经验。"
        },
        {
          title: "德邦证券携手非凸科技走进“乘长会客厅”",
          image: require("../assets/img/news/56/封面.jpg"),
          path: "/news56",
          tags: ["新闻", "德邦证券"],
          date: "2023年9月7日",
          abstract: "与机构投资者、私募管理人等特邀嘉宾，共同探讨前沿算法，分享独特见解，促进生态伙伴共同成长。"
        },
        {
          title: "非凸联合创始人李佐凡受邀出席复旦DS&BA项目座谈会",
          image: require("../assets/img/news/55/封面.jpg"),
          path: "/news55",
          tags: ["新闻", "校企合作"],
          date: "2023年8月24日",
          abstract: "与学校教授、老师在生源背景、课程教学、职业发展、学生培养和企业合作方面进行深入交流。"
        },
        {
          title: "2023苏黎世-深圳数量金融风险夏令营，非凸联合创始人李佐凡担任特邀讲师",
          image: require("../assets/img/news/54/封面.jpg"),
          path: "/news54",
          tags: ["新闻", "校企合作"],
          date: "2023年8月11日",
          abstract: "带领同学们了解国内外数量金融风险行业的前沿趋势，以及学习先进技术在该领域的应用和研究。"
        },
        {
          title: "海通证券&非凸科技，探讨量化发展及生态合作",
          image: require("../assets/img/news/53/封面.jpg"),
          path: "/news53",
          tags: ["新闻", "海通证券"],
          date: "2023年8月9日",
          abstract: "双方将继续发挥各自优势，达成更多层次的深度合作，推动量化行业高质量发展。"
        },
        {
          title: "中科大线上分享｜如何量化地找工作？",
          image: require("../assets/img/news/52/封面.jpg"),
          path: "/news52",
          tags: ["新闻", "校园宣讲"],
          date: "2023年8月3日",
          abstract: "非凸科技受邀参加中科大线上活动，分享量化前沿以及求职经验，助力同学们拿到心仪offer。"
        },
        {
          title: "非凸算法正式上线国信证券，携手为量化交易赋能",
          image: require("../assets/img/news/51/封面.jpg"),
          path: "/news51",
          tags: ["新闻", "国信证券"],
          date: "2023年7月28日",
          abstract: "基于项目方案的高成熟度，非凸科技快速完成了部署、测试及上线，满足了券商快速展业的迫切需求。"
        },
        {
          title: "国投证券（原“安信证券”）携手非凸科技、泽募家，共话量化未来投资之道",
          image: require("../assets/img/news/49/封面.jpg"),
          path: "/news49",
          tags: ["新闻", "国投证券"],
          date: "2023年7月24日",
          abstract: "感谢国投证券与泽募家的支持，期待未来有更多的联动和交流，打造合作共赢生态圈。"
        },
        {
          title: "非凸科技受邀出席国元证券“北京私募会”，探讨行业发展与合作机会",
          image: require("../assets/img/news/48/封面.jpg"),
          path: "/news48",
          tags: ["新闻", "国元证券"],
          date: "2023年7月20日",
          abstract: "与林亿资本、龙隐投资、中量财富等多家私募机构，共同探讨私募行业发展与合作机会。"
        },
        {
          title: "非凸科技受邀出席君华汇沙龙，探讨算法交易最佳实践",
          image: require("../assets/img/news/47/封面.jpg"),
          path: "/news47",
          tags: ["新闻", "君华私募基金汇"],
          date: "2023年7月18日",
          abstract: "与国泰君安证券及在场嘉宾，共同探讨算法交易的诸多优势和应用实践。"
        },
        {
          title: "广发证券携手非凸科技，探讨科技赋能量化",
          image: require("../assets/img/news/45/封面.jpg"),
          path: "/news45",
          tags: ["新闻", "广发证券"],
          date: "2023年7月17日",
          abstract: "特别邀请到千衍私募基金，与在场嘉宾共同探讨如何为量化行业高质量发展蓄势赋能。"
        },
        {
          title: "非凸科技与国信证券、富国基金，共探私募机构行业生态",
          image: require("../assets/img/news/46/封面.jpg"),
          path: "/news46",
          tags: ["新闻", "国信证券"],
          date: "2023年7月14日",
          abstract: "在追求极致的稳定与高效的同时，非凸科技也希望通过平台交流，不断推动量化行业提速发展。"
        },
        {
          title: "WAIC｜非凸科技受邀参展，揭秘AI与交易",
          image: require("../assets/img/news/44/封面.jpg"),
          path: "/news44",
          tags: ["新闻", "WAIC2023"],
          date: "2023年7月13日",
          abstract: "大会首次引入“开源集市”多元融合创新活动，汇聚了全球技术大牛、AI大咖，展示了开源魅力，分享了技术成果。"
        },
        {
          title: "非凸科技金牌赞助“第三届中国Rust开发者大会”",
          image: require("../assets/img/news/41/封面.jpg"),
          path: "/news41",
          tags: ["新闻", "Rust开发者大会"],
          date: "2023年6月21日",
          abstract: "非凸科技作为金牌赞助商，全力协助大会顺利开展，共同为中国 Rustaceans带来一场技术交流盛宴。"
        },
        {
          title: "国泰君安证券&非凸科技，探讨前沿技术赋能量化投资",
          image: require("../assets/img/news/39/封面.jpg"),
          path: "/news39",
          tags: ["新闻", "沙龙活动"],
          date: "2023年5月19日",
          abstract: "特别邀请到上海靖奇投资、深圳衍盛资产出席本次活动，分享行业洞察与专业见解，在座反响热烈。"
        },
        {
          title: "非凸科技在第五届全球金融科技创业大赛中荣获“最具创新力企业”奖",
          image: require("../assets/img/news/38/封面.jpg"),
          path: "/news38",
          tags: ["新闻", "大赛获奖"],
          date: "2023年5月17日",
          abstract: "作为50强企业受邀参加总决赛，获得荣誉奖项，在商业模式成熟度、市场地位、科技创新等多方面获得认可。"
        },
         {
          title: "非凸算法正式上线国联证券，赋能机构业务高质量发展",
          image: require("../assets/img/news/36/封面.jpg"),
          path: "/news36",
          tags: ["新闻", "国联证券"],
          date: "2023年5月12日",
          abstract: "在高性能交易、高效率风控、高质量服务等方面展开深度合作，共同为算法生态建设贡献力量。"
        },
        {
          title: "国投证券（原”安信证券“）&非凸科技，开展量化行业高质量交流活动",
          image: require("../assets/img/news/35/封面.jpg"),
          path: "/news35",
          tags: ["新闻", "沙龙活动"],
          date: "2023年4月26日",
          abstract: "本次活动特别邀请到量化管理人，以不同视角与行业伙伴共同探讨算法赋能及量化发展趋势。"
        },
        {
          title: "再添新伙伴！非凸算法正式上线国投证券（原”安信证券“）",
          image: require("../assets/img/news/33/封面.jpg"),
          path: "/news33",
          tags: ["新闻", "国投证券"],
          date: "2023年4月24日",
          abstract: "助力国投证券不断提升展业能力、丰富算法策略以及完善服务体系等，从而为机构客户提供差异化、多样化的算法服务。"
        },
        {
          title: "非凸科技受邀出席“发现美”量化私募交流会",
          image: require("../assets/img/news/32/封面.jpg"),
          path: "/news32",
          tags: ["新闻", "量化私募交流会"],
          date: "2023年4月4日",
          abstract: "交流会上，非凸科技首席架构师乔丹从技术的角度深度分享了非凸算法如何赋能量化交易，引发了激烈讨论。"
        },
        {
          title: "非凸科技受邀出席东北证券京津冀私募圈俱乐部线下交流会",
          image: require("../assets/img/news/31/封面.jpg"),
          path: "/news31",
          tags: ["新闻", "东北证券"],
          date: "2023年3月30日",
          abstract: "交流会上，非凸科技深度分享了非凸算法及其应用实践，并与在场嘉宾共同探讨了行业发展新趋势及算法合作新模式。"
        },
        {
          title: "非凸科技参加第五届全球金融科技创业大赛上海赛区总决赛",
          image: require("../assets/img/news/30/封面.jpg"),
          path: "/news30",
          tags: ["新闻", "创业大赛"],
          date: "2023年3月23日",
          abstract: "非凸科技经过层层选拔，凭借其创新性和优异性，在参赛的众多项目里脱颖而出并参与此次上海赛区的线下路演。"
        },
        {
          title: "高新赋能｜非凸科技荣获国家级“高新技术企业”认定",
          image: require("../assets/img/news/29/封面.jpg"),
          path: "/news29",
          tags: ["新闻", "高新技术企业"],
          date: "2023年3月17日",
          abstract: "此项荣誉是国家对非凸科技在技术创新、产品研发、服务能力等方面的高度认可，也是最佳激励。"
        },
        {
          title: "海通证券携手非凸科技，开展算法推广研讨会",
          image: require("../assets/img/news/27/封面.jpg"),
          path: "/news27",
          tags: ["新闻", "研讨会"],
          date: "2023年1月12日",
          abstract: "以客户需求为核心，发挥各自资源、技术、服务等优势，协同合作，持续创新，推动算法生态建设。"
        },
        {
          title: "新伙伴！非凸算法即将上线海通证券",
          image: require("../assets/img/news/26/封面.jpg"),
          path: "/news26",
          tags: ["新闻", "海通证券"],
          date: "2022年12月23日",
          abstract: "近日，非凸科技与海通证券达成总对总合作，发挥各自优势，共建量化生态。"
        },
        {
          title: "实力认可！非凸算法正式上线国泰君安证券",
          image: require("../assets/img/news/25/封面.jpg"),
          path: "/news25",
          tags: ["新闻", "国泰君安证券"],
          date: "2022年12月16日",
          abstract: "近日，非凸智能算法上线国泰君安证券，凭借高绩效、稳运行、强技术获得券商及管理人的高度认可。"
        },
        // {
        //   title: "非凸开源项目：高速时间戳fttime",
        //   image: require("../assets/img/news/24/封面.jpg"),
        //   path: "/news24",
        //   tags: ["新闻","开源项目"],
        //   date: "2022年12月7日",
        //   abstract: "fttime可以将单次获取时间戳的延时降低到1-2ns，对比标准库缩短80+%。"
        // },
        {
          title: "非凸科技受邀参加西部证券2023年度资本市场峰会",
          image: require("../assets/img/news/23/封面.jpg"),
          path: "/news23",
          tags: ["新闻", "西部证券"],
          date: "2022年12月1日",
          abstract: "李佐凡从机器学习的角度来分享量化投资的技术应用，干货满满，精彩纷呈！"
        },
        {
          title: "非凸算法上线银河证券，助力机构交易业务",
          image: require("../assets/img/news/22/封面.jpg"),
          path: "/news22",
          tags: ["新闻", "银河证券"],
          date: "2022年11月30日",
          abstract: "非凸智能算法上线银河证券启明iTrade算法中心平台，助力机构提升展业能力、丰富算法策略，完善服务体系等。"
        },
        {
          title: "携手共赢！非凸科技与多家头部系统供应商合作",
          image: require("../assets/img/news/21/封面.jpg"),
          path: "/news21",
          tags: ["新闻", "系统供应商"],
          date: "2022年11月23日",
          abstract: "近日，多家头部系统供应商向非凸科技发出邀约合作，聚焦量化服务生态圈展开深入交流。"
        },
        {
          title: "非凸最新算法全面上线中泰证券XTP",
          image: require("../assets/img/news/20/封面.jpg"),
          path: "/news20",
          tags: ["新闻", "中泰证券"],
          date: "2022年11月14日",
          abstract: "近日，非凸智能算法最新版本正式上线中泰证券 XTP，助力算法生态圈快速发展。"
        },
        {
          title: "复旦大学 DS&BA项目启动，非凸联合创始人李佐凡担任硕士职业导师",
          image: require("../assets/img/news/19/封面.jpg"),
          path: "/news19",
          tags: ["新闻", "校企合作"],
          date: "2022年11月9日",
          abstract: "近日，非凸科技与复旦大学管理学院展开“产学研教”深入合作，希望通过DS&BA项目提高学生的实践能力和应用能力。"
        },
        {
          title: "非凸科技受邀出席中泰证券第四届XTP开发者大会",
          image: require("../assets/img/news/18/封面.jpg"),
          path: "/news18",
          tags: ["新闻", "中泰XTP大会"],
          date: "2022年12月1日",
          abstract: "11月6日，非凸科技首席架构师乔丹在会上分享了“Rust在算法交易中的实际应用与积极效应”，满满技术干货！"
        },
        {
          title: "开源！非凸 Rust 高性能日志库ftlog",
          image: require("../assets/img/news/17/封面.jpg"),
          path: "/news17",
          tags: ["新闻", "开源项目"],
          date: "2022年11月1日",
          abstract: "ftlog支持“受限写入”以及“时间切分”等功能，具备显著的性能优势。"
        },
        {
          title: "非凸CTO李佐凡对话复旦徐云杰教授",
          image: require("../assets/img/news/16/封面.jpg"),
          path: "/news16",
          tags: ["新闻", "校企合作"],
          date: "2022年10月12日",
          abstract: "交流会上，双方主要在学生培养、项目课题、实践创新、实习细则等方面展开了深入交流。"
        },
        {
          title: "抱自然，聚力前行！迎接变化，持续迭代！",
          image: require("../assets/img/news/15/封面.jpg"),
          path: "/news15",
          tags: ["新闻", "团建活动"],
          date: "2022年10月9日",
          abstract: "两天一晚的“东方绿舟”团建活动，为非凸人再次启航蓄满能量。"
        },
        {
          title: "非凸智能算法即将上线中国银河证券",
          image: require("../assets/img/news/14/01-封面.jpg"),
          path: "/news14",
          tags: ["新闻", "银河证券"],
          date: "2022年9月16日",
          abstract: "致力于为更多的量化交易类客户提供智能拆单服务，有效增厚超额收益。"
        },
        {
          title: "机器学习如何助力量化投资？非凸联合创始人李佐凡为你揭秘",
          image: require("../assets/img/news/13/01-封面.jpg"),
          path: "/news13",
          tags: ["新闻", "直播宣讲"],
          date: "2022年9月8日",
          abstract: "9月8日，李佐凡受邀参加复旦大学管理学院职业发展中心组织的职业课堂活动，为同学们答疑解惑。"
        },
        {
          title: "数据安全，系统稳定，非凸运营团队做客户背后坚定的力量",
          image: require("../assets/img/news/12/01-封面.jpg"),
          path: "/news12",
          tags: ["新闻", "团建活动"],
          date: "2022年9月3日",
          abstract: "9月3日，非凸运营团队在今年疫情后迎来首次团建活动，希望营造团结协作、奋发向上的团队氛围。"
        },
        {
          title: "受邀出席 Rust 开发者大会｜Rust如何助力量化高频交易？",
          image: require("../assets/img/news/11/01-封面.jpg"),
          path: "/news11",
          tags: ["新闻", "Rust 开发者大会"],
          date: "2022年7月31日",
          abstract: "7月31日，非凸科技量化策略负责人陆一洲受邀出席“第二届中国 Rust 开发者大会”，带来”Rust 应用于量化行业“的主题分享。"
        },
        {
          title: "校企融合，打造金融科技应用人才高地",
          image: require("../assets/img/news/09/校企合作-封面.jpg"),
          path: "/news09",
          tags: ["新闻", "校企合作"],
          date: "2022年5月29日",
          abstract: "非凸科技与江苏师范大学科文学院经济学院签署校企战略合作协议，双方将在人才培养、科研合作、技术创新等方面展开深度合作。"
        },
        {
          title: "非凸科技与东北证券达成战略合作",
          image: require("../assets/img/news/08/战略合作封面.jpg"),
          path: "/news08",
          tags: ["新闻", "东北证券"],
          date: "2022年5月12日",
          abstract: "非凸科技作为技术支持战略合作单位，联合东北证券，打造覆盖机构全生命周期的服务生态圈。"
        },
        {
          title: "非凸科技&Rust中文社区，线上技术分享会成功举办",
          image: require("../assets/img/news/06/新闻封面.jpg"),
          path: "/news06",
          tags: ["技术分享", "直播宣讲"],
          date: "2022年3月6日",
          abstract: "3月6日，非凸科技北京分公司负责人朱为老师就“国内外量化发展现状、Rust 在量化领域应用、典型工作场景以及招聘职位等方面进行了分享。"
        },
        {
          title: "非凸科技&CSDN，招聘直播宣讲会圆满落幕",
          image: require("../assets/img/news/05/cover.jpg"),
          path: "/news05",
          tags: ["招聘", "直播宣讲"],
          date: "2022年2月11日",
          abstract: "非凸科技荣获 CSDN 颁发的“人才合作伙伴”证书，成为 CSDN 线上工程师岗位招聘年度合作伙伴。"
        },
        {
          title: "非凸算法助阵 X-Club XTP 开发者大会",
          image: require("../assets/img/news/04/cover.jpg"),
          path: "/news04",
          tags: ["新闻", "中泰XTP大会"],
          date: "2020年12月18日",
          abstract: "12月18日，中泰证券主办的“X-Club XTP 开发者大会”在上海隆重举行，非凸算法助阵。"
        },
        {
          title: "非凸科技受邀出席第十届 HED 峰会",
          image: require("../assets/img/news/03/cover.jpg"),
          path: "/news03",
          tags: ["新闻", "第十届HED峰会"],
          date: "2020年12月8日",
          abstract: "12月8日，财视中国联合中泰证券举办的“第十届 HED 峰会”在深圳隆重举行，非凸科技受邀出席。"
        },
        {
          title: "非凸智能算法正式上线中泰 XTP 系统",
          image: require("../assets/img/news/02/cover.jpg"),
          path: "/news02",
          tags: ["新闻", "中泰证券"],
          date: "2020年9月14日",
          abstract: "非凸智能算法于2020年9月14日作为首家智能算法服务商正式上线中泰 XTP 系统。"
        },
        {
          title: "非凸科技与中泰证券达成合作",
          image: require("../assets/img/news/01/cover.jpg"),
          path: "/news01",
          tags: ["重磅", "中泰证券"],
          date: "2020年7月29日",
          abstract: "近日，上海非凸智能科技有限公司（简称“非凸科技”）与中泰证券股份有限公司（简称“中泰证券”）达成合作。"
        },
      ],
      newsToDisplay: [],
      currentPage: 1,
      perPage: 10
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.news.length / this.perPage)
    }
  },
  mounted() {
    this.newsToDisplay = this.news.slice(0, this.perPage)
  },
  methods: {
    pageItemClicked(index, event) {
      this.newsToDisplay = this.news.slice(index * this.perPage, (index + 1) * this.perPage)
      document.querySelectorAll('.page-item').forEach((v) => {
        v.classList.remove('active')
      });
      console.log(event.currentTarget);
      event.currentTarget.parentElement.classList.add('active')
    }
  },
};
</script>

<style scoped>
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/banner1.jpeg");
  background-position-y: -30%;
}
.page-item {
  cursor: pointer;
}
</style>
